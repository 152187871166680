<template>
  <PromiseContainer :promises.sync="promises">
    <overview
        :items="getClients"
        :headers="headers"
        :filters="filters"
        header-text="Klanten"
        add-btn-text="Nieuwe klant"
        :add-item-route="{name: RouteNames.CLIENT_CREATE}"
        :detail-page-function="detailPageFunction">
      <template v-slot:item.status="{item}">
        <v-chip :color="item.active ? 'green': 'red'" label>
          {{ item.status }}
        </v-chip>
      </template>
    </overview>
  </PromiseContainer>
</template>

<script>
import Overview from "@/components/shared/Overview";
import RouteNames from "@/router/RouteNames";
import PromiseContainer from "@/components/shared/PromiseContainer.vue"

export default {
  name: "ClientOverview.vue",
  components: {
    PromiseContainer,
    Overview
  },
  data() {
    return {
      RouteNames,
      headers: [
        {
          text: "Naam",
          value: 'clientName'
        },
        {
          text: "Actieve/totale projecten",
          value: 'activeTotalProjects'
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      filters: [
        {
          title: "Status",
          options: [
            {
              id: "client#status#active",
              label: "Actief",
              active: false,
              predicate: (client) => client.active,
            },
            {
              id: "client#status#inactive",
              label: "Inactief",
              active: false,
              predicate: (client) => !client.active,
            }
          ]
        },
      ],
      clients: [],
      promises: [this.$store.dispatch("clientsModule/fetchNotArchivedClients").then(data => this.clients = data)],
      detailPageFunction: (client) => {
        return {
          name: RouteNames.CLIENT_DETAIL,
          params: {
            clientId: client.id
          }
        }
      },
    }
  },
  methods: {
    getActiveTotalProjectsOfClient(client) {
      const projects = client.projects;
      if (!projects) {
        return "0/0";
      }
      const activeProjects = projects.filter(project => project.active).length;
      const totalProjects = projects.length;
      return `${activeProjects}/${totalProjects}`
    },
  },
  computed: {
    getClients() {
      const clients = this.clients;
      if (!clients) {
        return [];
      }
      return clients.map(client => ({
        ...client,
        status: client.active ? 'actief' : 'inactief',
        activeTotalProjects: this.getActiveTotalProjectsOfClient(client)
      }))
    },
  }
}
</script>